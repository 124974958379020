.App {
  text-align: center;
  background-color: #282c34;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 75vh;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header {
  background-color: #282c34;
  justify-content: center;
  font-size: calc(20px + 4vmin);
  color: white;
  margin: 0;
  display:flex;
  justify-content: flex-start;
}

.parent {
  padding: calc(100px + 2vmin) 0 0 0;
}

.kazooItems {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: calc(10px + 2vmin) 0 0 0;
}

.kazooItems:first-child {
  padding: calc(100px + 2vmin) 0 0 0;
}

.kazooButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.App-link {
  color: #61dafb;
}

.maxSettings {
  display: flex;
  justify-content: space-evenly;
}

.radioButtons {
  display:flex;
}

.maxVolume {
  padding: 0 10px 0 0
}

.kazooDropDown {
  background: #f2f2f2;
  border: 1px solid;
  border-color: white #f7f7f7 whitesmoke;
  border-radius: 3px;
  width: 50px;
  margin: 0 15px 0 0;
}

.kazooPaddingLeft {
  margin: 0 0 0 5px;
}

input[type="text"] {
  background: #282c38;
  color:white;
  border: 3px solid;
  border-color: white;;
  border-radius: 3px;
  margin-left: 5px;
  padding-left: 5px;
}

button {
  background: #f2f2f2;
  border: 1px solid;
  border-color: white #f7f7f7 whitesmoke;
  border-radius: 3px;
  margin-left: 5px;
}

.timeSlider {
  width: 50vw;
  margin: 25px 0 100px 0;
}

.sliderContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.clearButton {
  margin-left:3vw;
}

.mainGroupingButtons {
  margin-left: 3vw;
}

.soundBar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 200px;
  margin-top: 15px;
  flex-wrap: nowrap;

}

.kazooTrash {
  margin: 5px 0 0 10px;
  color: #D9534F;
}

.radio {
  margin-left: 10px;
}

.purpleColor {
  color:purple;
}

.slideInClass {
  animation: slidein 1s;
}

.slideOutClass {
  animation: slideout 1s;
}

.MuiGrid-container {
  width:100%;
  display:flex;
  flex-wrap:nowrap;
  box-sizing:border-box;
}

.volumeSlider {
  display: flex;
  flex-wrap: nowrap !important;
}
@keyframes slidein {
  from {
    margin-left: 100%;
    opacity: 0
  }

  to {
    margin-left: 0%;
    opacity:1
  }
}

@keyframes slideout {
  to {
    margin-left: 100%;
    opacity: 0
  }

  from {
    margin-left: 0%;
    opacity:1
  }
}
a {
  color: black;
}

a:visited {
  color: black;
}

a:link {
  color: black;
}

a:hover {
  color: black;
}

a:active {
  color: black;
}

a:focus {
  color: black;
}

.authorText {
  font-size: calc(8px + 1vmin);
  padding-bottom: 15px;
  color: black;
  font-weight: 700;
}

@media only screen and (max-width: 600px) {
  .kazooItems {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: calc(10px + 2vmin) 0 0 0;
    border-bottom: 2px white solid;
  }
}

/* ____________________ MUSIC SHEET CSS ___________________ */
$cat_color-sheet: #000000;
$cat_color-sheet-note: #000000;
$_sheet-border: 1px solid $cat_color-sheet;
$_sheet-padding: 0.3em;
$_sheet-note-width: $_sheet-padding + 0.1em;

@mixin font-size($size) {
  font-size: #{$size/10}rem;
}

* {
  box-sizing: border-box;
}

.cat_sheet {
  padding-top: 1.3em;
  padding-bottom: 1.3em;
  text-align: center;
  line-height: 1.4;
  @include font-size(20);
}

.cat_sheet__lines {
  display: block;
  position: relative;
  border-top: $_sheet-border;
  height: 0;
  border-bottom: $_sheet-border;
  padding-top: $_sheet-padding;
  padding-bottom: $_sheet-padding + 0.03;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 100%;
    padding-top: $_sheet-padding;
    padding-bottom: $_sheet-padding + 0.03;
    border-top: $_sheet-border;
  }

  &:after {
    border-bottom: $_sheet-border;
  }

  &:before {
    position:absolute;
    top: -0.4em;
  }
}

.sheetLine {

}

.sheet {
  background-color: #282c34;
  padding-bottom: 300px;
}

.overAllSheet {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.bars {
  width: 75%;
  background-color: white;
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
}

.cat_sheet__notes-wrapper {
  $_width: 100%;
  position: absolute;
  width: $_width;
  top: 0;
  left: 0%;
}

%_sheet-stick-down {
  &:before {
    content: '';
    border-left: 1px solid $cat_color-sheet-note;
    height: 1em;
    position: relative;
    left: -(($_sheet-note-width/2) - (($_sheet-note-width/2) / 8));
  }
}

%_sheet-stick-up {
  &:before {
    content: '';
    border-right: 2px solid $cat_color-sheet-note;
    height: 1em;
    position: relative;
    left: ($_sheet-note-width/2) - (($_sheet-note-width/2) / 8);
    top: -1.15em;
  }
}

.cat_sheet__note {
  height: $_sheet-padding;
  width: $_sheet-note-width;
  background-color: $cat_color-sheet-note;
  border-radius: #{$_sheet-note-width "/" $_sheet-padding};
  position: absolute;

  &.cat_sheet__note--A {
    @extend %_sheet-stick-up;
    top: 0.33em;
  }

  &.cat_sheet__note--B {
    @extend %_sheet-stick-up;
    top: 0.18em;
  }

  &.cat_sheet__note--C {
    @extend %_sheet-stick-down;
    top: 0.03em;
  }

  &.cat_sheet__note--D {
    @extend %_sheet-stick-down;
    top: -.12em;
  }

  &.cat_sheet__note--G {
    @extend %_sheet-stick-up;
    top: 0.48em;
  }

  &.cat_sheet__note--F {
    @extend %_sheet-stick-up;
    top: 0.63em;
  }

  &.cat_sheet__note--E {
    @extend %_sheet-stick-up;
    top: 0.78em;
  }
}
